class BreakPointHelper {
  constructor(targetElement, classNames, minWidth = 0, maxWidth = Infinity) {
    this.targetElement = document.querySelector(targetElement);
    this.classNames = Array.isArray(classNames) ? classNames : [classNames];
    this.minWidth = minWidth;
    this.maxWidth = maxWidth;

    // Bind the resize event
    this.handleResize = this.handleResize.bind(this);
    window.addEventListener('resize', this.handleResize);

    // Initial check
    this.handleResize();
  }

  handleResize() {
    const screenWidth = window.innerWidth;
    if (screenWidth >= this.minWidth && screenWidth <= this.maxWidth) {
      this.classNames.forEach(className => this.targetElement.classList.add(className));
    } else {
      this.classNames.forEach(className => this.targetElement.classList.remove(className));
    }
  }
}

export default BreakPointHelper;
