class ScrollWatcher {
  constructor(targetElement, classNames, scrollThreshold = 0, windowWidthTeshold = 0) {
    this.targetElement = document.querySelector(targetElement);
    this.classNames = Array.isArray(classNames) ? classNames : [classNames];
    this.scrollThreshold = scrollThreshold;
    this.windowWidthTeshold = windowWidthTeshold; // Stop work if the screen size is lower than the treshold

    // Bind the scroll event
    this.handleScroll = this.handleScroll.bind(this);
    window.addEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    if (window.innerWidth > this.windowWidthTeshold) {
      if (window.scrollY > this.scrollThreshold) {
        this.classNames.forEach(className => this.targetElement.classList.add(className));
      } else {
        this.classNames.forEach(className => this.targetElement.classList.remove(className));
      }
    }
  }
}

export default ScrollWatcher;
