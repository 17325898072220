// Load Styles
import '../scss/main.scss';

// Load Bootstrap init
import {initBootstrap} from "./bootstrap.js";

// Custom Slider
import Slider from "./slider.js";

// Custom scroll watcher
import ScrollWatcher from "./ScrollWatcher.js";
import BreakPointHelper from "./BreakPointHelper.js";

// Loading bootstrap with optional features
initBootstrap({
  tooltip: true,
  popover: true,
  toasts: true,
});

// this slide must only be used with more than one element, no point in use it for a single image or label,
// and for this reason it's going break in this case
const slider = new Slider('how-it-works');

// ScrollWatche event to navbar
document.addEventListener('DOMContentLoaded', () => {
  const navbarToggler = new ScrollWatcher(
    '#idNav',
    ['scrolled', 'navbar-light', 'bg-light'],
    50,
    980
  );
});

// BreakPointHelper to mobile navbar
document.addEventListener('DOMContentLoaded', () => {
  const navbarSizeToggler = new BreakPointHelper(
    '#idNav',
    ['scrolled', 'navbar-light', 'bg-light'],
    0,
    980
  );
});
