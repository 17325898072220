class Slider {
  constructor(
    sliderId,
    slideSelector = '.slide',
    labelSelector = '.label',
    prevButtonSelector = '.prev',
    nextButtonSelector = '.next'
  ) {
    const container = document.getElementById(sliderId);

    this.slides = container.querySelectorAll(slideSelector);
    this.labels = container.querySelectorAll(labelSelector);
    this.prevButton = container.querySelector(prevButtonSelector);
    this.nextButton = container.querySelector(nextButtonSelector);

    this.current = 0;
    this.cycling = false;//change to true to make it cycle, but be aware this isn't fully implemented
    this.interval = null;

    this.prevButton.addEventListener('click', () => this.prevSlide());
    this.nextButton.addEventListener('click', () => this.nextSlide());

    this.init();
  }

  updateCurrentSlide(index) {
    this.slides.forEach(slide => {
      slide.classList.remove('active');
    });

    this.slides[index].classList.add('active');
  }

  updateCurrentLabel(index) {
    this.labels.forEach(label => {
      label.classList.remove('active');
    });

    this.labels[index].classList.add('active');
  }

  updateCurrent(index) {
    this.updateCurrentSlide(index);
    this.updateCurrentLabel(index);
  }

  nextSlide() {
    this.current++;
    if (this.current === this.slides.length) {
      this.current = 0;
    }
    this.updateCurrent(this.current);
  }

  prevSlide() {
    this.current--;
    if (this.current < 0) {
      this.current = this.slides.length - 1;
    }
    this.updateCurrent(this.current);
  }

  startCycling() {
    this.interval = setInterval(() => {
      this.nextSlide();
    }, 5000);
  }

  init() {
    this.updateCurrent(0);
    if (this.cycling) {
      this.startCycling();
    }
  }

  stopCycling() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
}

export default Slider;

